import { ReactNode, ComponentProps, CSSProperties } from "react";

import { Button as AntdButton } from "antd";
import { clsx } from "clsx";

import Icon, { IconSizes } from "../Icon";

import styles from "./RVButton.module.scss";

/**
 * For antd Tooltips to work well on disabled buttons, we need this workaround from https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
 * */
export function getTooltipButtonStyles(disabled?: boolean): CSSProperties {
    return { pointerEvents: disabled ? "none" : "auto" };
}

/**
 * For antd Tooltips to work well on disabled buttons, we need this workaround from https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
 * */
export function getTooltipButtonContainerStyles(disabled?: boolean): CSSProperties {
    return { cursor: disabled ? "not-allowed" : "pointer" };
}

export type RVButtonProps = ComponentProps<typeof AntdButton> & {
    linkVariant?: "text-link";
    textVariant?: "icon";
    children?: ReactNode | ReactNode[];
    icon?: string | JSX.Element;
    iconSize?: IconSizes;
};

export const clsRaisedButton = styles.raised;

export function RVButton({
    children,
    type = "default",
    // new prop to make the link button look like a text link
    linkVariant,
    textVariant,
    icon,
    iconSize,
    className = "",
    ...props
}: RVButtonProps): JSX.Element {
    const usedIcon = typeof icon === "string" ? <Icon icon={icon} size={iconSize ?? IconSizes.BUTTON} /> : icon;

    return (
        <AntdButton
            type={type}
            icon={usedIcon}
            className={clsx(
                styles.button,
                {
                    [styles[type]]: !!type,
                    [styles[linkVariant as string]]: !!linkVariant,
                    [styles[textVariant as string]]: !!textVariant,
                },
                className
            )}
            {...props}
        >
            {children && <div className={styles.innerText}>{children}</div>}
        </AntdButton>
    );
}

RVButton.Group = AntdButton.Group;
