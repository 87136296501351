import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
    startManagerAction as callStartManagerAction,
    stopManagerAction as callStopManagerAction,
} from "Services/ConversationsApiService";
import { postMessageToParentWithManagerActionIfNeeded } from "Services/embed/CrossDomainMessenger";
import { renderErrorMessage } from "Services/LoggingService";

import { selectAgentInformation } from "../agent/AgentInformationSlice";
import { selectBrandAvailableStatuses } from "../brand";
import { clearConference, setConference } from "../conferences/ConferenceStateSlice";
import { selectConference } from "../conferences/Selectors";
import { RootState, ThunkAction } from "../Storage";
import { clearManagerAction as clearManagerActionReducer, setActionData, setLoading } from "./ManagerActionStateSlice";
import { selectActiveAction, selectCallSid } from "./Selectors";

import type { ManagerAction } from "Pages/tasks/TaskDetails/ManagerCallControls/ManagerCallControlConstants";
import type { RVTaskInstance } from "Pages/tasks/types/RVTaskInstance";

export const startManagerAction = createAsyncThunk<
    void,
    { managerAction: ManagerAction; taskSid: string; taskInfo: RVTaskInstance },
    { state: RootState; dispatch: ThunkDispatch<RootState, unknown, AnyAction> }
>("managerAction/start", async ({ managerAction, taskSid, taskInfo }, { getState, dispatch }) => {
    dispatch(setLoading(managerAction));
    const availableStatuses = selectBrandAvailableStatuses(getState());
    const { workerUri, workerSid } = selectAgentInformation(getState());
    const callSid = selectCallSid(getState());

    if (!workerUri || !workerSid) {
        dispatch(setLoading(null));
        return renderErrorMessage({
            content: `Failed to start ${managerAction} call`,
        });
    }

    // TODO: actually manage failures in this endpoint
    // we send callSid only for the case when we want to switch from one manager action to another, otherwise it's null
    const actionResponse = await callStartManagerAction({
        taskSid,
        managerContactUri: workerUri,
        action: managerAction,
        twilioWorkerSid: workerSid,
        status: availableStatuses.find((s) => !s.available)?.name ?? "Offline",
        callSid,
    });

    if (taskInfo) {
        postMessageToParentWithManagerActionIfNeeded(
            {
                contactPhone: taskInfo.contactPhone,
                externalId: taskInfo.contactExternalId,
                profileId: taskInfo.profileId,
                taskType: taskInfo.attributes.title || taskInfo.type,
                agentEmail: taskInfo.attributes.acceptedByEmail,
            },
            managerAction === "barge" ? "started-barging" : "started-listening"
        );
    }

    dispatch(setConference({ connected: true }));
    dispatch(setActionData({ activeAction: actionResponse.action, callSid: actionResponse.callSid, taskSid }));
    dispatch(setLoading(null));
});

export const stopManagerAction = createAsyncThunk<
    void,
    string,
    { state: RootState; dispatch: ThunkDispatch<RootState, unknown, AnyAction> }
>("managerAction/stop", async (taskSid: string, { getState, dispatch }) => {
    const currentAction = selectActiveAction(getState());
    dispatch(setLoading(currentAction));
    const callSid = selectCallSid(getState());
    const conference = selectConference(getState());

    if (!callSid || !conference?.connected) {
        dispatch(setLoading(null));
        return renderErrorMessage({ content: "Call not found" });
    }

    try {
        await callStopManagerAction(taskSid, callSid);
    } catch (e) {
        dispatch(setLoading(null));
        return renderErrorMessage({ content: `Unable to stop to ${currentAction}` });
    }

    dispatch(clearConference());
    dispatch(clearManagerActionReducer());
});

export function clearManagerAction(): ThunkAction<void, RootState, any, AnyAction> {
    return function thunk(dispatch: ThunkDispatch<RootState, unknown, AnyAction>): void {
        dispatch(clearConference());
        dispatch(clearManagerActionReducer());
    };
}
