import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { useFlags } from "Services/FeatureFlagService";

import {
    DEFAULT_QUERY_LOCATION,
    getPrerecordedVoiceMessagesQueryWithTracking,
    getPrerecordedVoiceMessagesForUserQueryWithTracking,
    getOldPrerecordedVoiceMessagesQueryWithTracking,
    getOldPrerecordedVoiceMessagesForUserQueryWithTracking,
    type PrerecordedVoiceMessagesResponse,
    type PrerecordedVoiceMessagesForUserResponse,
} from "../../queries";

import type { PrerecordedVoiceMessage, RecordingType } from "Types/Recordings";

type HookProps = {
    invokedLocation?: string;
    type?: RecordingType;
};

const emptyMessage: Array<PrerecordedVoiceMessage> = [];

export const usePrerecordedVoiceMessageData = ({ invokedLocation = DEFAULT_QUERY_LOCATION, type }: HookProps) => {
    // RD-9726 remove this once we have subBrand support
    const { hasVmDropSubBrandSelection } = useFlags();

    const query = useMemo(() => {
        return hasVmDropSubBrandSelection
            ? getPrerecordedVoiceMessagesQueryWithTracking(invokedLocation)
            : getOldPrerecordedVoiceMessagesQueryWithTracking(invokedLocation);
    }, [invokedLocation, hasVmDropSubBrandSelection]);

    const { loading, data, refetch } = useQuery<PrerecordedVoiceMessagesResponse>(query, {
        fetchPolicy: "no-cache",
        variables: type ? { type } : undefined,
    });

    return {
        loading,
        data: data?.getPrerecordedVoiceMessages || emptyMessage,
        refetch,
    };
};

export const useAgentRecordingsData = ({ invokedLocation = DEFAULT_QUERY_LOCATION }: HookProps) => {
    // RD-9726 remove this once we have subBrand support
    const { hasVmDropSubBrandSelection } = useFlags();

    const query = useMemo(() => {
        return hasVmDropSubBrandSelection
            ? getPrerecordedVoiceMessagesForUserQueryWithTracking(invokedLocation)
            : getOldPrerecordedVoiceMessagesForUserQueryWithTracking(invokedLocation);
    }, [invokedLocation, hasVmDropSubBrandSelection]);

    const { loading, data, refetch } = useQuery<PrerecordedVoiceMessagesForUserResponse>(query, {
        fetchPolicy: "no-cache",
    });

    return {
        loading,
        data: data?.getPrerecordedVoiceMessagesForUser || emptyMessage,
        refetch,
    };
};
