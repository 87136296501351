import { message } from "antd";
import DOMPurify from "dompurify";

import { addEventEmitterListener, removeEventEmitterListener } from "Services/server-sent-events/EventEmitter";

import type { MessageType } from "@regal-voice/shared-types";

type ConferenceMessage = MessageType["twilio"]["conference.message"];

const DISPLAY_TIME_BY_TYPE = {
    error: 10,
    warning: 6,
    info: 3,
    success: 3,
} as const satisfies Record<ConferenceMessage["type"], number>;

const showConferenceMessageToast = ({ message: text, type }: ConferenceMessage): void => {
    message[type]({
        content: (
            <span
                // eslint-disable-next-line react/no-danger -- This use is properly sanitized
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text),
                }}
            ></span>
        ),
        duration: DISPLAY_TIME_BY_TYPE[type],
    });
};

export const subscribeToConferenceSSE = () => {
    addEventEmitterListener("twilio", "conference.message", showConferenceMessageToast);
};

export const unsubscribeFromConferenceSSE = () => {
    removeEventEmitterListener("conference.message", showConferenceMessageToast);
};
