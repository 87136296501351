import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import { PopoverWithTracking } from "Utils/PopoverWithTracking";

import { VoicemailListForm } from "../../../VoicemailListForm/VoicemailListForm";

import styles from "./VoicemailSettingsPopover.module.scss";

export interface VoicemailSettingsPopoverProps {
    open: boolean;
    loading: boolean;
    disabled: boolean;
    setOpen: (open: boolean) => void;
    onVoicemailDrop: () => Promise<void>;
    toggleModal: () => void;
}

export function VoicemailSettingsPopover({
    open,
    setOpen,
    onVoicemailDrop,
    loading,
    disabled,
    toggleModal,
}: VoicemailSettingsPopoverProps): JSX.Element {
    return (
        <PopoverWithTracking
            id="transfers-popover"
            open={open}
            onOpenChange={setOpen}
            trigger="click"
            placement="top"
            content={<VoicemailListForm dropVoicemail={onVoicemailDrop} isDroppingVoicemail={loading} />}
        >
            <div
                className={clsx(styles.buttonContainer, {
                    [styles.disabled]: disabled,
                    [styles.active]: open,
                })}
                onClick={toggleModal}
            >
                <div className={styles.iconContainer}>
                    <Icon
                        icon={open && !disabled ? "arrow-down" : "arrow-up"}
                        size={IconSizes.XSMALL}
                        className={clsx(styles.icon, {
                            [styles.open]: open && !disabled,
                            [styles.disabled]: disabled,
                        })}
                    />
                </div>
            </div>
        </PopoverWithTracking>
    );
}
