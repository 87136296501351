import { useCallback, useMemo } from "react";

import { Form, Select, Skeleton, Typography } from "antd";
import { clsx } from "clsx";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { RECORDING_TYPE_OPTIONS } from "Pages/settings/Recordings/constants";
import { usePrerecordedVoiceMessageData } from "ServerData/PrerecordedVoiceMessage/Hooks/usePrerecordedVoiceMessageData/usePrerecordedVoiceMessageData";

import styles from "./VoicemailListForm.module.scss";

export interface VoicemailListFormProps {
    isDroppingVoicemail?: boolean;
    dropVoicemail: () => Promise<void>;
}

export function VoicemailListForm({ dropVoicemail, isDroppingVoicemail }: VoicemailListFormProps) {
    // TODO: This is temporary (DEMO), we will need a separate endpoint to retrieve the correct voicemails for the agent
    const { loading, data } = usePrerecordedVoiceMessageData({ type: RECORDING_TYPE_OPTIONS.AGENT_VOICEMAIL });
    const options = useMemo(
        () =>
            data?.map((voicemail) => ({
                label: voicemail.friendlyName,
                value: voicemail.id,
            })),
        [data]
    );
    const [form] = Form.useForm();
    const submitForm = useCallback(() => form.submit(), [form]);

    if (loading) {
        return (
            <div data-testid="voicemail-list-loading" className={styles.container}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Typography.Title level={5} className={styles.title}>
                Select Voicemail
            </Typography.Title>
            <Form
                form={form}
                initialValues={{
                    type: options?.[0]?.value,
                }}
                onFinish={dropVoicemail}
            >
                <Form.Item name="type" className={styles.formItem}>
                    <Select options={options} onChange={() => {}} />
                </Form.Item>
                <Form.Item className={clsx(styles.actionButton, "flex-row justify-end")}>
                    <RVButton
                        size="middle"
                        type="primary"
                        data-testid="transfer-button"
                        onClick={submitForm}
                        loading={isDroppingVoicemail}
                    >
                        Drop Voicemail
                    </RVButton>
                </Form.Item>
            </Form>
        </div>
    );
}
