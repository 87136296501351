import { useCallback, useState } from "react";

import { sleep } from "Services/HelpersService";

import { BUTTONS_MESSAGES } from "../../CallBar.consts";
import { CallBarButton } from "../CallBarButton";
import { VoicemailSettingsButton } from "./components/VoicemailSettingsButton/VoicemailSettingsButton";

import styles from "./VoicemailDropButton.module.scss";

export interface VoicemailDropButtonProps {
    dropActive?: boolean;
    voicemailDropDisabled: boolean;
    dropVoicemail: () => Promise<void>;
}
export function VoicemailDropButton({
    dropVoicemail,
    voicemailDropDisabled = false,
    dropActive,
}: VoicemailDropButtonProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleVoicemailDrop = useCallback(async () => {
        setLoading(true);
        try {
            //TODO: This is only to demonstrate the loading state, remove this line when BE is ready
            await sleep(1000);

            // TODO: When BE is ready, we need here to send the selected voicemail to the server via dropVoicemail
            await dropVoicemail();

            setOpen(false);
        } catch (error) {
            return Promise.reject(error);
        } finally {
            setLoading(false);
        }
    }, [dropVoicemail]);

    const toggleModal = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <div className={styles.container}>
            <VoicemailSettingsButton
                voicemailDropDisabled={voicemailDropDisabled}
                open={open}
                setOpen={setOpen}
                onVoicemailDrop={handleVoicemailDrop}
                loading={loading}
                toggleModal={toggleModal}
            />
            <CallBarButton
                id="voicemailDrop"
                active={!!dropActive}
                action={dropVoicemail}
                icon="voicemail"
                disabled={voicemailDropDisabled}
                messages={BUTTONS_MESSAGES.voicemailDrop}
            />
        </div>
    );
}
