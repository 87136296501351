import { gql } from "@apollo/client";

import { PrerecordedVoiceMessage } from "Types/Recordings";

export const DEFAULT_QUERY_LOCATION = "GQLGetPrerecordedVoiceMessages";
export type PrerecordedVoiceMessagesResponse = {
    getPrerecordedVoiceMessages: PrerecordedVoiceMessage[];
};

export type PrerecordedVoiceMessagesForUserResponse = {
    getPrerecordedVoiceMessagesForUser: PrerecordedVoiceMessage[];
};

export const getPrerecordedVoiceMessagesQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking}($type: String) {
        getPrerecordedVoiceMessages(type: $type) {
            id
            isDefault
            createdAt
            createdBy
            updatedAt
            updatedBy
            type
            friendlyName
            recordingFilename
            url
            subBrands
            phoneCallCampaigns {
                id
                campaign {
                    id
                    name
                    state
                    friendlyId
                }
            }
           
        }
    }`;
};

export const getPrerecordedVoiceMessagesForUserQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking} {
        getPrerecordedVoiceMessagesForUser {
            id
            isDefault
            createdAt
            createdBy
            updatedAt
            updatedBy
            type
            friendlyName
            recordingFilename
            url
            subBrands
            phoneCallCampaigns {
                id
                campaign {
                    id
                    name
                    state
                    friendlyId
                }
            }
        }
    }`;
};

// RD-9726
// we needed to keep this for backwards compatibility
// becasue the backend doesnt support subBrands or phoneCallCampaigns yet,
//so if we try to query the page will break
export const getOldPrerecordedVoiceMessagesQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking}($type: String) {
        getPrerecordedVoiceMessages(type: $type) {
            id
            isDefault
            createdAt
            createdBy
            updatedAt
            updatedBy
            type
            friendlyName
            recordingFilename
            url
        }
    }`;
};

export const getOldPrerecordedVoiceMessagesForUserQueryWithTracking = (tracking = DEFAULT_QUERY_LOCATION) => {
    return gql`query ${tracking} {
        getPrerecordedVoiceMessagesForUser {
            id
            isDefault
            createdAt
            createdBy
            updatedAt
            updatedBy
            type
            friendlyName
            recordingFilename
            url
        }
    }`;
};
